<template>
  <div />
</template>

<script>

export default {
  async mounted() {
    const { key, token, cs_token: csToken } = this.$route.query

    // TODO: Create wrapper for the `localStorage.setItem` method that handles this
    if (key) {
      localStorage.setItem('key', key)
      if (token) {
        localStorage.setItem(`token_${key}`, token)
      }
      if (csToken) {
        localStorage.setItem(`cs_token_${key}`, csToken)
      }
    }

    window.location = '/'
  },
}
</script>
